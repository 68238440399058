import './App.css';
import Hero from './MyComponents/Hero/Hero';
import Program from './MyComponents/Programs/Program';
import Reason from './MyComponents/Reasons/Reason';
import Plan from './MyComponents/Plans/Plan'
import Testimonial from './MyComponents/Testimonials/Testimonial';
import Join from './MyComponents/Joins/Join';
import Footer from './MyComponents/Footer/Footer';

function App() {
  return (
    <div className="App">
          <Hero />
          <Program />
          <Reason />
          <Plan/>
          <Testimonial/>
          <Join/>
          <Footer/>
    </div>
  );
}

export default App;
