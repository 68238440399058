import React from 'react'
import './Join.css'
import {useRef} from 'react'
import emailjs from '@emailjs/browser'
const Join = () => {

    const form= useRef()

    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs.sendForm('service_i4sh21t', 'template_y6c6852', form.current, 'WSIoM4-FTtyUD_E_5')
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });
      };

  return (
    <div className='Join' id='join-us'>
        <div className='left-j'>
            <hr />
            <div>
                <span className='stroke-text'>Ready to </span>
                <span>Level Up</span>
            </div>
            <div>
                <span className='stroke-text'>Your Body</span>
                <span>With Us?</span>
            </div>
        </div>
        <div className='right-j'>
            <form ref={form} action='' className='email-container' onSubmit={sendEmail}>
                <input type="email" name="user_email" placeholder="Enter Your Email"/>
                <button className="btn btn-j">Join Now</button>
            </form>
        </div>
      
    </div>
  )
}

export default Join
